import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { createStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import PlayButton from "../buttons/Play"
import ChromeButton from "../buttons/Chrome"
import ProductHuntButton from "../buttons/ProductHunt"

const useStyles = makeStyles((theme) => createStyles({
  main: {
    textAlign: "center",
  },
}))

const DownloadSimple = () => {
  const classes = useStyles()
  return (
    <Fragment>
      <Grid id={"download"} container alignItems={"center"} justify={"center"} spacing={2} className={classes.main}>
        <Grid item xs={12} sm={6} md={4}>
          <ChromeButton/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ProductHuntButton/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <PlayButton/>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default DownloadSimple
